import React from "react";
import yellow from "../../../accerts/svg/emsat/yellowdot.svg";
import blue from "../../../accerts/svg/emsat/sopebluedote.svg";
import green from "../../../accerts/svg/emsat/greendot.svg";
import red from "../../../accerts/svg/emsat/reddot.svg";
import gray from "../../../accerts/svg/emsat/graydot.svg";
const EMsatTimeManagement = ({
  totalTime,
  Avgtime,
  Idealtime,
  correctAnswers,
  incorrectAnswers,
  unanswered,
}) => {
  const secondsToMinutes = (seconds) => (seconds / 60).toFixed(2);
  const secondsandMnt = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedSeconds =
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    return `${minutes}m ${Math.round(formattedSeconds)}s`;
  };
  const getAvgtime = () => {
    if (totalTime && Avgtime) {
      return `${(Avgtime / totalTime) * 100}%`;
    }
  };
  const getIdealtime = () => {
    if (totalTime && Idealtime) {
      return `${(secondsToMinutes(Idealtime) / totalTime) * 100}%`;
    }
  };
  const getAvgCorrecttime = () => {
    if (totalTime && Avgtime) {
      return `${(correctAnswers / totalTime) * 200}px`;
    }
  };
  const getAvgIncorrecttime = () => {
    if (totalTime && Idealtime) {
      return `${(incorrectAnswers / totalTime) * 200}px`;
    }
  };
  const getAvgUnanserdtime = () => {
    if (totalTime && Avgtime) {
      return `${(unanswered / totalTime) * 200}px`;
    }
  };

  return (
    <>
      <div className="Emsat-Rep_Sub_cont ">
        <div className="EMRep-subcontainer">
          <div className="EMRep-subcontainer1">
            <p className="EMRep-AvgText">
              <span>
                <img src={yellow} />
              </span>{" "}
              Your Avg. Time/Question:{" "}
              <span className="EMRep-value">{secondsandMnt(Avgtime)}</span>
            </p>
            <div className="EMRep-horydashedLine"></div>
            <div className="EMRep-AvgTextCont">
              <p className="EMRep-AvgText">
                <span>
                  <img src={blue} />
                </span>{" "}
                Ideal Avg. Time/ Question:{" "}
                <span className="EMRep-value">{secondsandMnt(Idealtime)} </span>
              </p>
            </div>
          </div>
          <div className="EMRep-subcontainer2">
            <div
              className="EMsat-barchart "
              style={{ height: getAvgtime() }}
            ></div>
            <div
              className="EMsat-barchart "
              style={{ height: getIdealtime(), backgroundColor: "#9FD6FF" }}
            ></div>
          </div>
        </div>
        <div className="EMRep-verticalLine"></div>

        <div className="EMRep-TM-secCont">
          <div className="EMRep-TM-subcotainer">
            <div className="EMRep-TM-heading">Average Time / Question </div>
            <div className="EMRep-TM-textCont">
              <div className="EMRep-TM-INertextCont">
                <p className="EMRep-TM-text">
                  <span>
                    <img src={green} alt="green dot" />
                  </span>
                  Correct Qs:
                  <span className="EMRep-pointer_Qus">
                    {secondsandMnt(correctAnswers)}
                  </span>
                </p>
              </div>
              <div className="EMRep-TM-INertextCont">
                <p className="EMRep-TM-text">
                  <span>
                    <img src={red} alt="green dot" />
                  </span>
                  Inorrect Qs:
                  <span className="EMRep-pointer_Qus">
                    {secondsandMnt(incorrectAnswers)}
                  </span>
                </p>
              </div>
              <div className="EMRep-TM-INertextCont">
                <p className="EMRep-TM-text">
                  <span>
                    <img src={gray} alt="green dot" />
                  </span>
                  Unattempted Qs:
                  <span className="EMRep-pointer_Qus">
                    {secondsandMnt(unanswered)}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div className="EMRep-TM-subcotainer">
            <div className="EMRep-TM-barcont">
              <div
                className="EMsat-barprogress "
                style={{
                  height: getAvgCorrecttime(),
                  backgroundColor: "#00BA88",
                }}
              ></div>
              <div
                className="EMsat-barprogress "
                style={{
                  height: getAvgIncorrecttime(),
                  backgroundColor: "#B7295A",
                }}
              ></div>
              <div
                className="EMsat-barprogress "
                style={{
                  height: getAvgUnanserdtime(),
                  backgroundColor: "#D9DBE9",
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile */}
      <div className="Emsat-Rep_Sub_contMob ">
        <div className="EMRep-subcontainer">
          <div className="EMRep-subcontainer2">
            <div
              className="EMsat-barchart "
              style={{ height: getAvgtime() }}
            ></div>
            <div
              className="EMsat-barchart "
              style={{ height: getIdealtime(), backgroundColor: "#9FD6FF" }}
            ></div>
          </div>
          <div className="EMRep-subcontainer1">
            <p className="EMRep-AvgText">
              <span>
                <img src={yellow} />
              </span>{" "}
              Your Avg. Time/Question:{" "}
              <span className="EMRep-value">{secondsandMnt(Avgtime)} </span>
            </p>
            <p className="EMRep-AvgText">
              <span>
                <img src={blue} />
              </span>{" "}
              Your Avg. Time/Question:{" "}
              <span className="EMRep-value">{secondsandMnt(Idealtime)} </span>
              <span> </span>
            </p>
          </div>
        </div>
        <div className="EMRep-verticalLine"></div>

        <div className="EMRep-TM-secContMOb">
          <div className="EMRep-TM-heading">Average Time / Question </div>

          <div className="EMRep-TM-subcotainer">
            <div className="EMRep-TM-barcont">
              <div
                className="EMsat-barprogress "
                style={{
                  height: getAvgCorrecttime(),
                  backgroundColor: "#00BA88",
                }}
              ></div>
              <div
                className="EMsat-barprogress "
                style={{
                  height: getAvgIncorrecttime(),
                  backgroundColor: "#B7295A",
                }}
              ></div>
              <div
                className="EMsat-barprogress "
                style={{
                  height: getAvgUnanserdtime(),
                  backgroundColor: "#D9DBE9",
                }}
              ></div>
            </div>
          </div>
          <div className="EMRep_graph_PMobCont">
            <p>
              <span>
                <img src={green} />
              </span>{" "}
              Correct Qs : {secondsandMnt(correctAnswers)}
            </p>
            <p>
              <span>
                <img src={red} />
              </span>{" "}
              Inorrect Qs : {secondsandMnt(incorrectAnswers)}
            </p>
            <p>
              <span>
                <img src={gray} />
              </span>{" "}
              Unattempted Qs : {secondsandMnt(unanswered)}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default EMsatTimeManagement;
