export const CONFIG_VARS = Object.freeze({
  baseUrl:
    //"https://adminpreprod.inmobiusinfinitylearn.com/api/v1/exams/advanced-report", //preprod
    "https://admin.inmobiusinfinitylearn.com/api/v1/exams/advanced-report", //prod

  //S3buket: "/reports",
  S3buket:
    //"https://s3.ap-south-1.amazonaws.com/inmobiusreports.devinfinitylearn.in/reorts", // pre prod
    "https://s3.ap-south-1.amazonaws.com/inmobiusreports.infinitylearn.com/reports", // prod
});
