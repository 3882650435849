import "./App.css";
import { EmsatReports } from "./pages/EmsatReports";
import { Home } from "./pages/Home";
import { OlympaidReport } from "./pages/Olaympaid";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ScoreReports } from "./pages/scoreReports";
import { HotsOlympaidReport } from "./pages/HotsOlympaid";

function App() {
  return (
    <div className="body-wrapbox theme-exam">
      <section className="student__onboarding__widget w-100">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route
              path="/score-report/:crnId/:eventId"
              element={<ScoreReports />}
            />
            <Route
              path="/iemsat-report/:crnId/:eventId"
              element={<EmsatReports />}
            />
            <Route
              path="/matholympiad-report/:crnId/:eventId"
              element={<OlympaidReport />}
            />
            <Route
              path="/hotsolympiad-report/:crnId/:eventId"
              element={<HotsOlympaidReport />}
            />
            {/* Add new route for "/login" */}
          </Routes>
        </Router>
      </section>
    </div>
  );
}

export default App;
