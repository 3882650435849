import React, { useState, useEffect } from "react";
import { LineChart } from "./components/EMsat/LineChart";
import { PaiChart } from "./components/EMsat/PaiChart";
import ColumnProgressBar from "./components/EMsat/EMsatTowerChart";
import TimeProgressors from "./components/EMsat/TimeProgressors";
import EMsatTimeManagement from "./components/EMsat/EMsatTimeManagement";
import Subjectwise from "./components/EMsat/Subjectwise";
import SubjectDetaiedReport from "./components/EMsat/SubjectDetaiedReport";
import header from "../accerts/svg/emsat/Frame 1410091859.svg";
import { useParams } from "react-router-dom";
import { CONFIG_VARS } from "./constants/configaration";
import BasicReport from "./components/ScoreREports/BasicReport";
import loaderGif from "../accerts/gifs/LoaderGif.gif";
import Timer from "../accerts/gifs/timerGIf.gif";
import footer from "../accerts/svg/footer.svg";

export const EmsatReports = () => {
  const { crnId, eventId } = useParams();
  const [reportData, setReportData] = useState(null);
  const [advanceReport, setAdvanceReport] = useState(null);
  const [progressordata, setProgressordata] = useState({});
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState();
  const [advaceLoader, setAdvaceLoader] = useState(false);

  const PostAdvancereport = async (eventId, crnId, reportType) => {
    setAdvaceLoader(true);
    try {
      const res = await fetch(
        `${CONFIG_VARS.baseUrl}?event_id=${eventId}&crn_id=${crnId}&event=${reportType}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Product-Id": "100",
            "Tenant-Id": "99999",
          },
          body: "", // Empty body as per your curl command
        }
      );

      if (res.status === 201) {
        const data = await res.json();
        console.log(data); // Log the response data if needed
        fetchReports(crnId, eventId);
      }
    } catch (err) {
      console.error("Error creating advanced report:", err);
    } finally {
      setAdvaceLoader(false);
    }
  };
  const fetchReports = async (crnId, eventId) => {
    setLoader(true);
    setLoader(true);
    const cacheBuster = new Date().getTime();
    const res = await fetch(
      `${CONFIG_VARS.S3buket}/${crnId}_${eventId}.json?cb=${cacheBuster}`
    );
    console.log(res.status);
    if (res?.status === 200) {
      const data = await res?.json();
      setReportData(data);
      setProgressordata(data?.basic_report?.section_wise_marks);

      const examSubmittedAt = new Date(data?.exam_submitted_at);
      const currentTime = new Date();
      const timeDifference = currentTime - examSubmittedAt;

      if (timeDifference > 2 * 60 * 60 * 1000) {
        if (!data?.advanced_report?.length > 0) {
          PostAdvancereport(eventId, crnId, "iemsat");
        } else {
          setAdvanceReport(
            Array.isArray(data?.advanced_report) ? data.advanced_report[0] : []
          );
        }
      }
      setLoader(false);
    } else if (res?.status !== 200) {
      setError("Reports are not available yet ..!");
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchReports(crnId, eventId);
  }, [crnId, eventId]);
  const formatDate = (dateString) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };
  console.log(advanceReport);
  const getQuestionStatusData = (advanceReport, status) => {
    return advanceReport?.question_status_time?.find(
      (item) => item.QUESTION_STATUS === status
    );
  };

  const correctData = getQuestionStatusData(advanceReport, "Correct");
  const incorrectData = getQuestionStatusData(advanceReport, "Incorrect");
  const unattemptedData = getQuestionStatusData(advanceReport, "Unattempted");

  return (
    <div className="Rep_main_cont">
      <div className="Mathheader">
        <img style={{ width: "100%" }} src={header} alt="Header" />
        <p className="examName mathExame">
          {reportData?.event_code} {reportData?.level}
        </p>
        <p className="studentName">{reportData?.first_name}</p>
        <p className="schoolid">{reportData?.school_name}</p>
        <p className="date">{formatDate(reportData?.exam_submitted_at)}</p>
        <p className="grade">{reportData?.grade}</p>
      </div>
      {!loader && !error ? (
        <div>
          {!advanceReport && (
            <>
              <BasicReport
                reportData={reportData}
                progressordata={progressordata}
              />
              {advaceLoader && (
                <div className="Rep_timer-cont">
                  <h1>But Wait!</h1>
                  <p className="Rep_timer-des">
                    <span>We are generating a detailed report for you.</span>
                  </p>

                  <img src={Timer} alt="Timer" />
                </div>
              )}
            </>
          )}
          {advanceReport && (
            <>
              <h3 style={{ display: "none" }} className="Rep_sub-head">
                Summary
              </h3>
              <div style={{ display: "none" }} className="EMsatRep_score_card">
                <div className="EMsatRep_score_carditem">
                  <p>.</p>
                  <p>
                    {" "}
                    Your avg. time/question is close to the ideal avg.
                    time/question of this paper.
                  </p>
                </div>

                <div className="EMsatRep_score_carditem">
                  <p>.</p>
                  <p>
                    {" "}
                    You performed well in Physics. You need expert guidance in
                    Mathematics.
                  </p>
                </div>

                <div className="EMsatRep_score_carditem">
                  <p>.</p>{" "}
                  <p>
                    {" "}
                    Your Score is higher than the average Tier-1 College cutoff.
                    Well done!
                  </p>
                </div>
              </div>

              <h3 className="Rep_sub-head">Highlights</h3>

              <div className="EMRep_Sub_cont">
                <PaiChart
                  totalMarks={
                    advanceReport?.exam_details[0]?.MAX_MARKS_PER_EXAM
                  }
                  scoredMarks={advanceReport?.exam_details[0]?.CURRENT_MARKS}
                  accuracy={Math.round(
                    advanceReport?.exam_details[0]?.ACCURACY
                  )}
                />
                <LineChart
                  totalScore={
                    advanceReport?.exam_details[0]?.MAX_MARKS_PER_EXAM
                  }
                  userScore={advanceReport?.exam_details[0]?.CURRENT_MARKS}
                  cutoff={advanceReport?.exam_details[0]?.CUT_OFF}
                />
              </div>
              <h3 className="Rep_sub-head">Performance</h3>
              <div className="Rep_card-cont">
                <ColumnProgressBar
                  totalQuestions={
                    advanceReport?.exam_details[0]?.MAX_MARKS_PER_EXAM
                  }
                  correctAnswers={advanceReport?.exam_details[0]?.CORRECT}
                  incorrectAnswers={advanceReport?.exam_details[0]?.INCORRECT}
                  unanswered={advanceReport?.exam_details[0]?.UNATTEMPTED}
                />{" "}
                <TimeProgressors
                  totaltime={advanceReport?.exam_details[0]?.TOTAL_EXAM_TIME}
                  totalTimeSpend={
                    advanceReport?.exam_details[0]?.TOTAL_TIME_TAKEN
                  }
                  correctAnswerstimeSpend={correctData?.TOTAL_TIME ?? 0}
                  incorrectAnswerstimeSpend={incorrectData?.TOTAL_TIME ?? 0}
                  unansweredtimeSend={unattemptedData?.TOTAL_TIME ?? 0}
                />
              </div>
              <h3 className="Rep_sub-head">Time Management</h3>
              <EMsatTimeManagement
                totalTime={advanceReport?.exam_details[0]?.TOTAL_EXAM_TIME}
                Avgtime={advanceReport?.exam_details[0]?.AVG_TIME_TAKEN}
                Idealtime={
                  advanceReport?.exam_details[0]
                    ?.IDEAL_AVG_TIME_PER_QUESTION_FOR_EVERY_PAPER_IN_SECONDS
                }
                correctAnswers={correctData?.AVG_TIME ?? 0}
                incorrectAnswers={incorrectData?.AVG_TIME ?? 0}
                unanswered={unattemptedData?.AVG_TIME ?? 0}
              />
              <h3 className="Rep_sub-head">Subject-wise Performance</h3>
              <Subjectwise reportData={advanceReport?.sections} />
              <SubjectDetaiedReport
                SubSection={advanceReport?.sub_section_details}
                chapterData={advanceReport?.sections}
              />
            </>
          )}
        </div>
      ) : (
        <div className="Loader">
          {!error ? (
            <>
              {" "}
              <img src={loaderGif} alt="loading" />
              <h4>Wait we are prepraing your reports....</h4>
            </>
          ) : (
            <div>{error}</div>
          )}
        </div>
      )}
      <img
        style={{ width: "100%", marginTop: "1rem" }}
        src={footer}
        alt="footer"
      />
    </div>
  );
};
