import React from "react";
import Green from "../../../accerts/svg/reportspage/greendot.svg";
import Red from "../../../accerts/svg/reportspage/reddot.svg";
import Gray from "../../../accerts/svg/reportspage/graydot.svg";

const CircleProgressBar = ({
  totalMarks,
  correctMarks,
  incorrectMarks,
  unattempted,
}) => {
  const radius = 48.8857;
  const circumference = 2 * Math.PI * radius;

  const greenProgress = (correctMarks / totalMarks) * 100;
  const redProgress = (incorrectMarks / totalMarks) * 100;

  const greenStrokeLength = (greenProgress / 100) * circumference;
  const redStrokeLength = (redProgress / 100) * circumference;

  const neutralLength = circumference - greenStrokeLength - redStrokeLength;
  return (
    <div className="Rep_graph_card">
      <h3 className="Rep_graph_cardHead">Questions Attempted</h3>
      <div className="Rep_graph_cont">
        <div className="circle-cont1">
          <svg width="118" height="118" viewBox="0 0 118 118" fill="none">
            <circle
              cx="59"
              cy="59"
              r={radius}
              stroke="#EFF0F7"
              strokeWidth="20.2286"
            />
            <circle
              cx="59"
              cy="59"
              r={radius}
              stroke="#2CBE6A"
              strokeWidth="20.2286"
              strokeDasharray={`${greenStrokeLength} ${
                neutralLength + redStrokeLength
              }`}
              strokeDashoffset={circumference}
              strokeLinecap="round"
              transform="rotate(-90 59 59)"
            />
            <circle
              cx="59"
              cy="59"
              r={radius}
              stroke="#FF5353"
              strokeWidth="20.2286"
              strokeDasharray={`${redStrokeLength} ${
                neutralLength + greenStrokeLength
              }`}
              strokeDashoffset={circumference - greenStrokeLength}
              strokeLinecap="round"
              transform="rotate(-90 59 59)"
            />
          </svg>
        </div>
        <div className="circle-cont2">
          <svg width="80" height="" viewBox="0 0 118 118" fill="none">
            <circle
              cx="59"
              cy="59"
              r={radius}
              stroke="#EFF0F7"
              strokeWidth="20.2286"
            />
            <circle
              cx="59"
              cy="59"
              r={radius}
              stroke="#2CBE6A"
              strokeWidth="20.2286"
              strokeDasharray={`${greenStrokeLength} ${
                neutralLength + redStrokeLength
              }`}
              strokeDashoffset={circumference}
              strokeLinecap="round"
              transform="rotate(-90 59 59)"
            />
            <circle
              cx="59"
              cy="59"
              r={radius}
              stroke="#FF5353"
              strokeWidth="20.2286"
              strokeDasharray={`${redStrokeLength} ${
                neutralLength + greenStrokeLength
              }`}
              strokeDashoffset={circumference - greenStrokeLength}
              strokeLinecap="round"
              transform="rotate(-90 59 59)"
            />
          </svg>
        </div>
        <div className="EMRep_graph_PCont">
          <p>
            <span>
              <img src={Green} alt="green dot" />
            </span>{" "}
            Correct Qs : {correctMarks}/{totalMarks}
            <span></span>
          </p>
          <p>
            <span>
              <img src={Red} alt="red dot" />
            </span>{" "}
            Inorrect Qs : {incorrectMarks}/{totalMarks}
            <span></span>
          </p>
          <p>
            <span>
              <img src={Gray} alt="gray dot" />
            </span>{" "}
            Unattempted Qs : {unattempted}/{totalMarks}
            <span></span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default CircleProgressBar;
