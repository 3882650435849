import React from "react";

export const Home = () => {
  return (
    <div className="Rep_main_cont Rep_main_home">
      <h3>Welcome to Inmobuis Reports....!</h3>
      <h6>To view your Reports please click the link provided by your Rep.</h6>
      <h6>hello Inmobuis</h6>
    </div>
  );
};
