import React from "react";
import Phy from "../../../accerts/svg/emsat/Physics.svg";
import Chem from "../../../accerts/svg/emsat/Chemistry.svg";
import MAthIcon from "../../../accerts/svg/emsat/Mathametics.svg";
import zoo from "../../../accerts/svg/emsat/zoo.svg";
import botany from "../../../accerts/svg/emsat/botany.svg";
import Biology from "../../../accerts/svg/emsat/bio.svg";
import Logical from "../../../accerts/svg/emsat/logical.svg";
import Science from "../../../accerts/svg/emsat/science.svg";
import Critical from "../../../accerts/svg/emsat/critical.svg";
import Probelm from "../../../accerts/svg/emsat/problem.svg";

const Subjectwise = ({ reportData }) => {
  const getAvgCorrecttime = (item) => {
    return `${(item?.MARKS_ACHIEVED / item?.TOTAL_MARKS) * 100}%`;
  };

  const getAvgIncorrecttime = (item) => {
    return `${(item?.INCORRECT / item?.TOTAL_MARKS) * 100}%`;
  };

  const getImage = (subject) => {
    switch (subject) {
      case "Physics":
        return Phy;
      case "Chemistry":
        return Chem;
      case "Mathematics":
        return MAthIcon;
      case "Botany":
        return botany;
      case "Zoology":
        return zoo;
      case "Biology":
        return Biology;
      case "Science":
        return Science;
      case "Critical Thinking":
        return Critical;
      case "Logical Reasoning":
        return Logical;
      case "Problem Solving":
        return Probelm;
      default:
        return null;
    }
  };
  const convertToMinSec = (decimalMinutes) => {
    const minutes = Math.floor(decimalMinutes);
    const seconds = Math.round((decimalMinutes % 1) * 60); // Use modulus for the fractional part
    return `${minutes}m ${seconds}s`;
  };
  return (
    <>
      <div className="EMRep_SubW_cont">
        {reportData?.map((item, index) => (
          <div key={index} className="subjectwise-cont">
            <p className="subjectwise-name">
              <span>
                <img src={getImage(item?.SECTION)} alt={item.name} />
              </span>
              {item?.SECTION}
            </p>
            <p className="subjectwise-Score">
              <span>
                {item?.MARKS_ACHIEVED}/{item?.TOTAL_MARKS}{" "}
              </span>{" "}
              Correct
            </p>
            <div
              className="subjectwise-Progressbar"
              style={{
                backgroundColor: "#D9DBE9",
                borderRadius: "5px",
                overflow: "hidden",
                height: "10px",
              }}
            >
              <div
                style={{
                  width: `${
                    parseFloat(getAvgIncorrecttime(item)) +
                    parseFloat(getAvgCorrecttime(item))
                  }%`,
                  backgroundColor: "#B7295A",
                  height: "100%",
                  position: "relative",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    width: getAvgCorrecttime(item),
                    backgroundColor: "#00BA88",
                    height: "100%",
                    borderRadius: "5px",
                  }}
                ></div>
              </div>
            </div>
            <p className="subjectwise-Minuts">
              <span>{convertToMinSec(item?.AVG_TIME_TAKEN)}</span> Avg.
              time/question
            </p>
          </div>
        ))}
      </div>
      {/*m-web*/}
      <div className="EMRep_SubW_contMob">
        {reportData?.map((item, index) => (
          <div key={index} className="subjectwise-contMob">
            <div> {item?.SECTION}</div>
            <div className="subjectwise-inerMob">
              <p className="subjectwise-Score">
                <span>
                  {item?.MARKS_ACHIEVED}/{item?.TOTAL_MARKS}{" "}
                </span>{" "}
                Correct
              </p>
              <div
                className="subjectwise-Progressbar"
                style={{
                  backgroundColor: "#D9DBE9",
                  borderRadius: "5px",
                  overflow: "hidden",
                  height: "10px",
                }}
              >
                <div
                  style={{
                    width: `${
                      parseFloat(getAvgIncorrecttime(item)) +
                      parseFloat(getAvgCorrecttime(item))
                    }%`,
                    backgroundColor: "#B7295A",
                    height: "100%",
                    position: "relative",
                    borderRadius: "5px",
                  }}
                >
                  <div
                    style={{
                      width: getAvgCorrecttime(item),
                      backgroundColor: "#00BA88",
                      height: "100%",
                      borderRadius: "5px",
                    }}
                  ></div>
                </div>
              </div>
              <p className="subjectwise-Minuts">
                <span>{convertToMinSec(item.AVG_TIME_TAKEN)}</span>Avg.
                time/question
              </p>
            </div>
            {index < reportData?.length - 1 && (
              <div className="subjectwise-vertLineMob"></div>
            )}
          </div>
        ))}
      </div>
    </>
  );
};
export default Subjectwise;
